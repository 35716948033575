import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";

import { ModuleCaroussel } from "@mitim/react-mitim";

import { ModuleEncartListMt, tools } from "@mitim/react-mitim";
import Pub from "../Pub/Pub";
import { getGetActusCaroussel } from "../Home/Home";

// const BLEU_FONCE = "#7cb4d7";
// const BLEU_TURQUOISE = "#009d96";
// const ORANGE = "#e46644";
// const JAUNE = "#e3af56";

const ELEMENTS_ENCARTS_1 = [
	{
		titre: "MÉDITATION DU JOUR",
		couleurTitre: tools.ROUGE,
		lireLaSuiteHidden: true,
		sousTitre: "Lectures quotidiennes",
		imgSrc: "mt-parole-partagee_encart_1-1.jpg",
		textLink: "Accès",
		linkTo: "/meditation-quotidienne",
		brightness: "90%",
		contenu:
			'Écouter une méditation tirée du livre "Parle Seigneur, ton serviteur écoute"<br/><br/><br/>'
	},
	{
		titre: "LIVRES À ÉCOUTER",
		// titre: "LA BIBLE EN 1 AN",
		couleurTitre: tools.ROSE,
		lireLaSuiteHidden: true,
		// sousTitre: "La Bible à écouter",
		sousTitre: "Livres à écouter",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-parole-partagee_encart_1-2.jpg",
		linkTo: "/podcasts",
		brightness: "90%",
		contenu:
			"- Écouter la lecture du livre de Néhémie \"Ils rebâtiront sur d'anciennes ruines\"<br/>- Lecture intégrale de la bible, de l'Ancien et du Nouveau Testament"
	},
	{
		titre: "EXHORTATIONS",
		couleurTitre: tools.JAUNE_CLAIR,
		lireLaSuiteHidden: true,
		sousTitre: "Messages à lire et à écouter",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-parole-partagee_encart_1-3.jpg",
		linkTo: "/parole-partagee-spl/exhortationsAudio",
		brightness: "90%",
		contenu:
			"Lectures, messages, méditations ayant pour but de nourrir la foi des croyants et de tous ceux qui cherchent à connaître Dieu.<br/><br/>"
	}
];

const ELEMENTS_ENCARTS_2 = [
	{
		titre: "MESSAGES VIDÉO",
		couleurTitre: tools.VIOLET,
		lireLaSuiteHidden: true,
		sousTitre: "Messages à visionner",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-encart-aut.jpg",
		linkTo: "/messages-video",
		brightness: "95%",
		contenu:
			"Prédications filmées lors de rencontres d’Eglise et disponibles sur notre chaine Youtube.<br/><br/><br/>"
	},
	{
		titre: "ENSEIGNEMENT",
		couleurTitre: tools.MARRON,
		lireLaSuiteHidden: true,
		sousTitre: "Doctrine/théologie",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-encart-enseignement.jpg",
		linkTo: "/enseignement/Dieu",
		brightness: "95%",
		contenu:
			"Divers sujets de doctrine abordés lors de rencontres publiques dans le but de fonder la foi chrétienne. Connaissance de Dieu, doctrine de Jésus-Christ, le Saint Esprit, l’Eglise, l’au-delà …<br/>"
	},
	{
		titre: "DÉCOUVRIR L'ÉVANGILE",
		couleurTitre: tools.VERT_FONCE,
		lireLaSuiteHidden: true,
		sousTitre: "Réponses bibliques",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-encart-mont.jpg",
		linkTo: "/decouvrir-l-evangile/messages%20audios",
		brightness: "95%",
		contenu:
			"Pour ceux qui désirent une première approche de la foi chrétienne. Divers thèmes traités, format audio ou écrit.<br/><br/>"
	}
];

const ELEMENTS_ENCARTS_3 = [
	{
		titre: "TÉMOIGNAGES",
		couleurTitre: tools.BLEU_TURQUOISE,
		lireLaSuiteHidden: true,
		sousTitre: "Parcours de vie",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-parole-partagee_encart_2-2.jpg",
		linkTo: "/temoignages",
		brightness: "95%",
		contenu:
			"Plusieurs témoignages de personnes qui ont rencontré Jésus-Christ et dont la vie a été transformée (format audio ou écrit).<br/><br/>"
	},
	{
		titre: "REVUE",
		couleurTitre: tools.VERT,
		lireLaSuiteHidden: true,
		sousTitre: "Lumières reçues au fil du temps",
		imgSrc: "mt-parole-partagee_encart_2-1.jpg",
		textLink: "Accès",
		linkTo: "/revue",
		brightness: "95%",
		contenu:
			"Partager au fur et à mesure les réflexions du moment, telle était l’idée à l’origine du lancement de la collection « Lumières reçues au fil du temps »."
	},
	{
		titre: "LIVRES",
		couleurTitre: tools.BLEU,
		lireLaSuiteHidden: true,
		sousTitre: "Enseignement et édification",
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "mt-parole-partagee_encart_2-3.jpg",
		brightness: "90%",
		linkTo: "/livres/toutLesLivres",
		contenu:
			"Trouver des livres d'enseignement et d'édification publiés par la Mission Timothée et Calvin Éditions.<br/><br/>"
	}
];

class ParolePartagee extends Page {
	state = {
		hover: false,
		elementsALaUneParolePartagee: [],
		actus: []
	};

	componentDidMount() {
		// getKeyValuePublique("site-mission-a-la-une-parole-partagee", val => {
		// 	this.setState({
		// 		elementsALaUneParolePartagee: val
		// 	});
		// });
		getGetActusCaroussel(1983, this, () => {});
	}

	toggleHover() {
		this.setState({ hover: !this.state.hover });
	}

	render() {
		return (
			<>
				<div
					className="container-fluid  no-gutters bg-gris"
					style={{ minHeight: "480px" }}
				>
					<div className="container pb-5 ">
						<h1 className="contenu-h1 pt-5 pb-5 text-uppercase">À LA UNE</h1>

						<div className="">
							<ModuleCaroussel elements={this.state.actus} className="p-5" />
						</div>
					</div>
				</div>

				<div
					className="container-fluid pb-lg-5 no-gutters pt-5 bg-gris2"
					style={{ minHeight: "480px" }}
				>
					<div className="container pt-lg-5">
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_1} />
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_2} />
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_3} />
					</div>
				</div>

				{/* <div
					className="container-fluid pb-lg-5 no-gutters pt-5 bg-gris2"
					style={{ minHeight: "480px" }}
				>
					<div className="container pt-lg-5">
					</div>
				</div> */}
				<Pub />

				{/* <Search
					location={this.props.location}
					rootPath={this.props.match.path}
					defaultCategory="toutParolePartagee"
					categories={["toutCantique", "ouvrage", "exhortation", "conférence"]}
				/> */}
			</>
		);
	}
}

export default withTranslation()(ParolePartagee);
