import React from "react";
import { withTranslation } from "react-i18next";
import mtIconeCarteFrance from "../../resources/icons/carteFrance.svg";
import ImageMapper from "react-image-mapper";
import { Redirect } from "react-router-dom";
import {
	ModuleBlocGaucheDroite2,
	ModuleEncartListMt,
	tools,
	api
} from "@mitim/react-mitim";

import Icone1Formation from "../../resources/icons/Icone1-formation.svg";
import Icone2Formation from "../../resources/icons/Icone2-formation.svg";
import Icone3Formation from "../../resources/icons/Icone3-formation.svg";

import Page from "../Page";

const ELEMENTS_ENCARTS_1 = [
	{
		titre: "FORMATION MISSIONNAIRE",
		theme: "rouge",
		couleurTitre: tools.ROUGE,
		// textLink: "Télécharger le dossier",
		sousTitre: "Former des collaborateurs",
		iconSrc: Icone1Formation,
		imgSrc: "FormationEncart-1.jpg",
		// linkTo: `${tools.lienStorage}/MissionTimothee/data/formation missionnaire.pdf`,
		contenu:
			"La formation missionnaire s’adresse aux chrétiens qui, tout en ayant reçu un appel clair pour servir Dieu, désirent se former en vue de la prédication ou du diaconat tout en se consacrant au service des autres. Pour plus d'informations, vous pouvez nous contacter à l'adresse : devoirsformationtimothee@gmail.com"
	},
	{
		titre: "FORMATION BIBLIQUE",
		couleurTitre: tools.BLEU_TURQUOISE,
		sousTitre: "Doctrine et connaissance de Dieu",
		iconSrc: Icone2Formation,
		theme: "rouge",
		// textLink: "Télécharger le dossier",
		imgSrc: "FormationEncart-2.jpg",
		// linkTo: `${tools.lienStorage}/MissionTimothee/data/Formation biblique.pdf`,
		contenu:
			"Cette formation à distance s’adresse aux chrétiens qui souhaitent grandir dans la connaissance de Dieu par l’étude et la méditation de la Bible, tout en approfondissant leur compréhension de la doctrine chrétienne. Pour plus d'informations, vous pouvez nous contacter à l'adresse : devoirsformationtimothee@gmail.com"
	},
	{
		titre: "ESPACE ÉTUDIANTS",
		couleurTitre: tools.BLEU_FONCE,
		sousTitre: "Cours et devoirs",
		iconSrc: Icone3Formation,
		theme: "rouge",
		textLink: "Accès",
		imgSrc: "FormationEncart-3.jpg",
		linkTo: "/espace-candidats",
		roles: ["ROLE_CANDIDAT"],
		contenu:
			"Cet espace de travail, dédié aux étudiants en formation, donne accès aux cours écrits et audios, ainsi qu’aux énoncés des devoirs bibliques et dogmatiques propre au cursus de chacun.<br/><br/>"
	}
];

class Formation extends Page {
	render() {
		const data = this.props.data;
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid pb-lg-5 pt-5 no-gutters pt-2"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<div className="container">
						<ModuleEncartListMt data={ELEMENTS_ENCARTS_1} />
						{/* <div className="text-center pb-5 pt-5">
							<video
								controls
								autoplay="true"
								muted
								loop
								preload="none"
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									width: "100%",
									filter: "brightness(120%)"
								}}
							>
								<source
									src={`${tools.lienVideos}/formation.mp4`}
									type="video/mp4"
								/>
								<source
									src={`${tools.lienVideos}/formation.webm`}
									type="video/webm"
								/>
							</video>
						</div> */}
					</div>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters pb-5"
					style={{ minHeight: "480px", backgroundColor: "#e2e3e4" }}
				>
					<div className="container-fluid bg-gris2 no-gutters pt-5">
						<ModuleBlocGaucheDroite2
							data={{
								textPosition: "droite",
								imgSrc: "mt-formation-bible.jpg",
								titre: "POURQUOI SE FORMER ?",
								sousTitre: "Transmettre la foi, servir, soutenir",
								contenu: (
									<>
										<p>
											Le but de ces diverses formations est de permettre à des
											chrétiens de devenir collaborateurs, de les préparer à
											l’évangélisation au service de la Parole, au diaconat,
											afin qu’ils deviennent des serviteurs et des servantes de
											Dieu au sein des églises et partout où le Seigneur les
											enverra.
										</p>
										<p>
											Nous avons à cœur d’aider à la formation personnelle dans
											un esprit de collaboration étroite au sein des équipes et
											en contact permanent avec ceux qui les ont précédés. Ils
											pourront ainsi profiter de leur expérience et être
											préparés à conduire d’autres au Seigneur, puis les
											soutenir effi&shy;cace&shy;ment dans leur vie chrétienne.
										</p>
									</>
								),
								boutons: []
							}}
						/>
						<p>
							<br />
							<br />
						</p>
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation()(Formation);
