// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tools, Icones } from "@mitim/react-mitim";
// Components
import Media from "./Media";
import Header from "../Header/Header";
import Page from "../Page";
// Images
// CSS
// import '../../styles/Media.css';
import "./MediaArticleRevue.scss";
import * as communs from "./communs";
import download from "../../resources/icons/download.svg";

const TelechargementPdf = ({ idArticle }) => (
	<div className="telechargementComplet">
		<a
			href={`https://public:98HO9HOIUGOI98H@ws.timothee.fr/timedia/public/downloadArticleRevuePdf/${idArticle}`}
		>
			<div className="select pl-2 pr-2">
				<div
					className="pt-2 text-black ft-alegreya-sans-light"
					style={{ fontSize: "1.1em" }}
				>
					Version PDF
					<img
						style={{ float: "right", width: "18px" }}
						src={download}
						width={18}
					/>
				</div>
			</div>
		</a>
		<hr />
	</div>
);

export class MediaArticleRevue extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	componentDidMount() {}

	classOfCantique(fichier) {
		var classValue = "";
		if (fichier.fichier) {
			if (fichier.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	handleClick = fichier => {
		if (fichier.fichier) {
			console.log("this is:", fichier.fichier.sharedLink);
			console.log("this is:", fichier.fichier.id);
			this.props.app.setState(
				{
					cantiqueListen: fichier.fichier.sharedLink,
					id: fichier.fichier.id,
					rangMediaMedia: fichier.rang
				},
				() => {
					this.audioPlayer.current.play();
					this.audioPlayer.current.type = "audio/mp3";
				}
			);
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.fichiers
			.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			)
			.entries()) {
			if (this.props.app.state.rangMediaMedia < value.rang) {
				this.props.app.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang
					},
					() => {
						this.audioPlayer.current.play();
						this.audioPlayer.current.type = "audio/mp3";
					}
				);
				break;
			}
		}
	};

	auteurs() {
		return (
			<>
				{this.props.app.state.auteurs.length > 0 && (
					<>
						<h5>Auteurs / Compositeurs</h5>
						<ul>
							{this.props.app.state.auteurs.map(auteur => (
								<li key={auteur.auteur.id}>
									{auteur.auteur.prenom} {auteur.auteur.nom}
									{auteur.auteur.pays && <> - {auteur.auteur.pays}</>}
									{auteur.auteur.annees && <> - {auteur.auteur.annees}</>}
									{auteur.auteur.origine && <> - {auteur.auteur.origine}</>}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}

	render() {
		const media = this.props.app.state.media;

		const getContenu = () => {
			let contenu = media.contenu;
			contenu = contenu.replace(/ (?=[;!?]|»)/g, "\u00A0;");
			contenu = contenu.replace("« ", "«\u00A0");
			return contenu;
		};
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "parolePartagee.jpg",
						h1: "Article de revue",
						// h2: "LIVRES",
						h3: "Nourrir sa foi et sa reflexion",
						descriptionMeta: ""
					}}
				/>

				<div className="container pt-5 pb-5 article-revue">
					<div className="text-center pb-3">
						<span
							style={{ backgroundColor: this.props.couleur, fontSize: "1em" }}
							className="text-white pl-3 pr-3 pt-1 pb-1 text-center ft-lato-regular text-uppercase"
						>
							<strong>{media.serie ? media.serie.nom : "Sans thème"}</strong>
						</span>
					</div>
					<h1
						className="contenu-h1 text-uppercase ft-lato-regular pt-3"
						style={{ fontSize: "1.6em" }}
					>
						<strong>{media.titre}</strong>
					</h1>
					{media.sousTitre && (
						<h4
							className="contenu-h1 ft-lato-regular pt-3 text-center"
							style={{ fontSize: "1.6em" }}
						>
							{media.sousTitre}
						</h4>
					)}
					{this.props.app.state.auteurs.length > 1 && (
						<h4 className="ft-lato-light text-center pt-4">
							{this.props.app.state.auteurs.map((data, index) => (
								<>
									{data.auteur.prenom + " " + data.auteur.nom}
									{this.props.app.state.auteurs.length > 1 &&
										index < this.props.app.state.auteurs.length - 1 &&
										", "}
								</>
							))}
						</h4>
					)}
					{this.props.app.state.auteurs.length == 1 && (
						<h4 className="ft-lato-light text-center pt-4">
							{this.props.app.state.auteurs.map((data, index) => (
								<>{data.auteur.prenom + " " + data.auteur.nom}</>
							))}
						</h4>
					)}
					<h6 className="ft-lato-light text-center pt-2 nom-revue">
						<Link
							to={`/media/${this.props.app.state.mediaMedias[0].media.id}/${this.props.app.state.mediaMedias[0].media.nomRevue.nom} n° ${this.props.app.state.mediaMedias[0].media.numRevue}`}
						>
							{this.props.app.state.mediaMedias[0].media.nomRevue.nom +
								" n°" +
								this.props.app.state.mediaMedias[0].media.numRevue}{" "}
							| {this.props.app.state.mediaMedias[0].media.dateEdition}
						</Link>
					</h6>
					<div
						className="pt-4"
						dangerouslySetInnerHTML={{
							__html: getContenu()
							// __html: media.contenu
						}}
					/>
					<div className="pt-2"></div>
					{this.props.app.state.notes.length > 0 && (
						<>
							<h5>Notes et références</h5>
							<hr />
							<ul className="bas-de-page pt-4">
								{this.props.app.state.notes.map((data, index) => (
									<li id={"note" + data.numNote}>
										<span>
											<strong>{data.numNote + "."}</strong>
											{/* {" " + data.contenu} */}{" "}
											<span
												className=""
												dangerouslySetInnerHTML={{
													__html: data.contenu
												}}
											/>
										</span>
									</li>
								))}
							</ul>
						</>
					)}

					<TelechargementPdf idArticle={media.id} />
				</div>
			</>
		);
	}
}
